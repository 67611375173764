<template>
    <div class="page-container">
        <md-app md-waterfall md-mode="fixed">
            <md-app-toolbar class="md-primary">
                <div class="md-toolbar-row">
                    <div class="md-toolbar-section-start">
                        <img src="/img/logo-stock.png" alt="stock.ftvaccess.fr" class="logo" />
                        <h3 class="md-title">stock.ftvaccess.fr</h3>
                    </div>
                    <div class="md-toolbar-section-end">
                        <md-menu md-direction="bottom-end" :mdCloseOnClick="true" :mdCloseOnSelect="true">
                            <md-button class="md-icon-button" md-menu-trigger>
                                <md-icon>more_vert</md-icon>
                            </md-button>
                            <md-menu-content>
                                <md-menu-item v.if="$store.getters.logged" @click="$router.push('/stock')">
                                    <span>Recherche de programmes</span>
                                    <md-icon>search</md-icon>
                                </md-menu-item>
                                <md-menu-item @click="$router.push('/confidentialite')">
                                    <span>Politique de confidentialité</span>
                                    <md-icon>policy</md-icon>
                                </md-menu-item>
                                <md-menu-item @click="$router.push('/cgu')">
                                    <span>CGU</span>
                                    <md-icon>description</md-icon>
                                </md-menu-item>
                                <md-menu-item @click="$router.push('/legal')">
                                    <span>Mentions légales</span>
                                    <md-icon>gavel</md-icon>
                                </md-menu-item>
                                <md-menu-item  @click="logout">
                                    <span>Déconnexion</span>
                                    <md-icon>logout</md-icon>
                                </md-menu-item>
                            </md-menu-content>
                        </md-menu>
                    </div>
                </div>
            </md-app-toolbar>
            <md-app-content class="md-scrollbar md-alignment-top-center md-layout">
                <md-card md-with-hover class="md-layout-item md-size-30">
                    <md-card-header>
                        <h1 class="md-title">Modification mot de passe</h1>
                    </md-card-header>
                <form @submit.prevent="submit">

                <md-card-content>
                    <md-vuelidated>
                        <label>Nouveau mot de passe</label>
                        <md-input v-model.trim="form.password" type="password"></md-input>
                        <md-vuelidated-msg constraint="required">Merci de saisir votre nouveau mot de passe.</md-vuelidated-msg>
                        <md-vuelidated-msg constraint="password">Votre mot de passe doit contenir au minimum 9 caractères, contenir au minimum une lettre Majuscule, 1 lettre Minuscule et 1 chiffre</md-vuelidated-msg>
                        <span class="md-helper-text">Votre mot de passe ne doit pas avoir été utilisé lors des derniers 18 mois<br />
Il doit faire au minimum 9 caractères, contenir au minimum une lettre Majuscule, 1 lettre Miniscule, 1 chiffre</span>
                    </md-vuelidated>
                    </md-card-content>

                    <md-card-actions>
                        <br />
                        <br /><br /><br /><br/><br /><br /><br />
                        <md-button class="md-raised" type="submit">Modifier mon mot de passe</md-button>
                    </md-card-actions>
                    </form>
                
                </md-card>
            </md-app-content>
        </md-app>
    </div>
</template>
      

<script>
import {required, helpers} from 'vuelidate/lib/validators'
const password = helpers.regex('password', /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{9,}/)
import {changePassword} from '@/api/user'

export default {
  name: 'Password',
  data(){
      return {
          loader: null,
          form:{
              password:''
          }
      }
  },
  validations:{
      form:{
          password:{
              required,
              password
          }
      }
  },
  methods:{
      logout(){
            this.$store.dispatch('user/logout')
            .then(() => {
                this.$router.push({path: '/'});
            })
            .catch(() => {
                this.$alert("Il n'a pas été possible de vous deconnecter",'Error','error');
            })
        },
      submit(){
          this.$v.$touch();
          if(!this.$v.$invalid){
              // submission
              this.loader=this.$loading.show();
              changePassword(this.form)
              .then((data) => {
                  this.loader.hide();
                  this.$alert(data.message,'Success','success');
                  // reloading user info :
                this.$store.dispatch('user/getInfo').then(()=>{
                  this.$router.push({path: '/stock'})  
                });
              }).catch((e) => {
                  this.loader.hide();
                  console.log(e);
                  this.$alert(e.response.data.error,'Error','error');
              })
          }
      }
  }
  
}
</script>
<style lang="scss" scoped>
.md-app {
    min-height: 100vh;
    
    border: 1px solid rgba(#000, .12);
  }
img.logo{
    max-height: 50px;
}
.md-layout-item{
    flex-basis: 400px;
    min-width: 300px;
    max-width: 400px;
}
</style>